import React from "react";
import { Title } from "../../../styles/typography.styles";
import { Design, DesignRow, DesignCard, CardTitle, CardText } from "./ram.styles";
import { CardLine } from "../models.styles";
import Image from "../../elements/Image";

const RamDesign = ({data, width}) => {
  return (
    <Design>
      <Title color={'white'}>Design</Title>

      <DesignRow className={`container`}>
        {data.map(item => (
          <DesignCard key={item.id} width={width}>
            <Image fileName={item.image} alt={item.title}/>
            <CardTitle color={'white'}>{item.title}</CardTitle>
            <CardLine/>
            <CardText color={'#a7a7a7'}>
              <ul>
                {item.list.map((listItem, i) => (
                  <li key={i}>{listItem}</li>
                ))}
              </ul>
            </CardText>
          </DesignCard>
        ))}
      </DesignRow>
    </Design>
  )
}

export default RamDesign;