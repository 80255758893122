import React from "react";
import Image from "../../elements/Image";
import { OptionsCard, OptionsRow, CardText } from "./ram.styles";

const LonghornStandard = () => {
  return (
    <OptionsRow>
      <OptionsCard>
        <Image fileName={'options_ANT@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          GRUPA OCHRONY [ANT]

          <ul>
            <li>4 regulowane haki do mocowania ładunku</li>
            <li>Oświetlenie skrzynki ładunkowej</li>
            <li>Specjalistyczne zabezpieczenie skrzyni ładunkowej</li>
            <li>Przegroda ładunkowa (standard z Rambox)</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_A63@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          WYPOSAŻENIE 2 POZIOMU [A68]

          <ul>
            <li>19 głośników Harman Kardon</li>
            <li>Podgrzewane siedzenia tylne</li>
            <li>Wentylowane tylne siedzenia</li>
            <li>Bezprzewodowa podkładka do ładowania</li>
            <li>Stopień boczny elektryczny</li>
            <li>12-calowy wyświetlacz</li>
            <li>System martwego pola</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_ALP@2x.jpg'} alt={'RAM Longhorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          ZAAWANSOWANA GRUPA BEZPIECZEŃSTWA [ALP]

          <ul>
            <li>Tempomat adaptacyjny z funkcją Stop & Go</li>
            <li>Ostrzeżenie przed kolizją</li>
            <li>Asystent utrzymania pasa ruchu</li>
            <li>Równoległy i prostopadły asystent parkowania z funkcją Stop</li>
            <li>Kamera 360 stopni</li>
          </ul>
        </CardText>
      </OptionsCard>


    </OptionsRow>
  )
}

export default LonghornStandard